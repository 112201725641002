window._ = require('lodash');

import 'select2';
import 'jquery-ui/ui/widgets/autocomplete.js';

import DataTable from 'datatables.net-bs5';
window.DataTable = DataTable;

import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import './laravel-datatables/dataTables.buttons.js';
import './laravel-datatables/dataTables.renderers.js';

$.extend(true, DataTable.Buttons.defaults, {
    dom: {
        button: {
            liner: {
                tag: ""
            }
        },
    },
});

$.extend(DataTable.ext.classes, {
    sTable: "dataTable table table-striped table-bordered table-hover",
});